import React from 'react'
import styles from './Input.module.scss'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import { Text } from '../Typography'

const Input = ({
  type,
  value,
  placeholder,
  label,
  onChange,
  disabled,
  preventZeroValues,
  containerCustomClasses,
  labelCustomClasses,
  inputCustomClasses,
  ...args
}) => {
  const hasLabel = !!label
  const containerClasses = classNames(styles.container, containerCustomClasses)
  const inputClasses = classNames(
    styles.input,
    { [styles.noLabel]: !hasLabel },
    inputCustomClasses
  )
  const labelClasses = classNames(styles.label, labelCustomClasses)

  const onChangeText = (e) => {
    if (type === 'number') {
      let numberValue = parseFloat(e.target.value)

      if (!isNaN(args.max)) {
        if (numberValue > args.max) {
          numberValue = args.max
        }
      }

      if (!isNaN(args.min)) {
        if (numberValue < args.min) {
          numberValue = args.min
        }
      }

      if (preventZeroValues) {
        const v = e.target.value.replace(/[^\d]/, '')
        if (parseFloat(v) !== 0) {
          onChange(parseFloat(e.target.value))
        }
      } else {
        onChange(numberValue)
      }
      return
    }
    onChange(e.target.value)
  }

  return (
    <div className={containerClasses}>
      {hasLabel && (
        <Text weight='small' customClasses={[labelClasses]}>
          {label}
        </Text>
      )}
      <input
        type={type}
        value={value}
        onChange={onChangeText}
        placeholder={placeholder}
        className={inputClasses}
        disabled={disabled}
        {...args}
      />
    </div>
  )
}

Input.propTypes = {
  /**
   * Type of input
   */
  type: PropTypes.string,
  /**
   * Value of input
   */
  value: PropTypes.string,
  /**
   * Placeholder of input
   */
  placeholder: PropTypes.string,
  /**
   * Optional change handler
   */
  onChange: PropTypes.func,
  /**
   * Label for input
   */
  label: PropTypes.string,
  /**
   * Control to disable input
   */
  disabled: PropTypes.bool,
  /**
   * Prevents input of zero values if true
   */
  preventZeroValues: PropTypes.bool,
  /**
   * List of custom classes which apply on container
   */
  containerCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of custom classes which apply on label
   */
  labelCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of custom classes which apply on input
   */
  inputCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * Additional arguments to input
   */
  args: PropTypes.any
}

Input.defaultProps = {
  type: 'text',
  placeholder: 'Enter value...',
  disabled: false,
  preventZeroValues: false
}

export default Input

import React from 'react'
import PropTypes from 'prop-types'
import styles from './Button.module.scss'

import classNames from 'classnames'

const Button = ({ children, onClick, type, disabled, customClasses }) => {
  const classes = classNames(
    styles.button,
    styles[type],
    {
      [styles.disabled]: disabled,
      [styles.noLink]: !onClick
    },
    customClasses
  )
  return (
    <button onClick={onClick} className={classes}>
      {children}
    </button>
  )
}

Button.propTypes = {
  /**
   * Button contents
   */
  children: PropTypes.any.isRequired,
  /**
   * Type of button
   */
  type: PropTypes.oneOf(['primary', 'secondary', 'text']),
  /**
   * Control to disable button
   */
  disabled: PropTypes.bool,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * List of custom classes
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

Button.defaultProps = {
  children: 'Button',
  type: 'primary',
  disabled: false
}

export default Button

import React from 'react'
import styles from './Header.module.scss'
import Button from '../Button'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Title } from '../Typography'
import { ReactComponent as MegaLogo } from './mega-logo.svg'

const Header = ({
  children,
  brandClickHandler,
  logoutHandler,
  customClasses
}) => {
  const classes = classNames(styles.header, customClasses)
  const brandIconClasses = classNames({
    [styles.brandClickable]: !!brandClickHandler
  })
  const logoutClasses = classNames({
    [styles.logout]: !children
  })

  return (
    <header className={classes}>
      <MegaLogo onClick={brandClickHandler} className={brandIconClasses} />
      {children}
      {!!logoutHandler && (
        <Title level={3} customClasses={logoutClasses}>
          <Button type='text' onClick={logoutHandler}>
            Logout
          </Button>
        </Title>
      )}
    </header>
  )
}

Header.propTypes = {
  /**
   * Contents in the header
   */
  children: PropTypes.any,
  /**
   * Click handler for Mega brand logo
   */
  brandClickHandler: PropTypes.func,
  /**
   * Handler for log out button
   */
  logoutHandler: PropTypes.func.isRequired,
  /**
   * List of custom classes which apply on header
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

export default Header

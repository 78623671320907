import React from 'react'
import styles from './Divider.module.scss'
import PropTypes from 'prop-types'

import classNames from 'classnames'

const Divider = ({ children, placement, customClasses }) => {
  const hasChildren = !!children
  const dividerClasses = classNames(
    styles.divider,
    { [styles.noText]: !hasChildren },
    { [styles[placement]]: hasChildren },
    customClasses
  )

  return (
    <div className={dividerClasses}>
      <span className={styles.innerText}>{hasChildren ? children : ''}</span>
    </div>
  )
}

Divider.propTypes = {
  /**
   * Optional divider text
   */
  children: PropTypes.string,
  /**
   * Placement of text on divider
   */
  placement: PropTypes.oneOf(['center', 'left', 'right']),
  /**
   * List of custom classes which apply on divider
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

Divider.defaultProps = {
  placement: 'left'
}

export default Divider

import React from 'react'
import styles from './Card.module.scss'
import PropTypes from 'prop-types'

import classNames from 'classnames'

const Card = ({
  children,
  removeLeftMargin,
  removeRightMargin,
  customClasses
}) => {
  const cardClasses = classNames(
    styles.card,
    {
      [styles.leftMargin]: !removeLeftMargin,
      [styles.rightMargin]: !removeRightMargin
    },
    customClasses
  )
  return <div className={cardClasses}>{children}</div>
}

Card.propTypes = {
  /**
   * Contents in the card
   */
  children: PropTypes.any,
  /**
   * If true, removes left margin of 50px
   */
  removeLeftMargin: PropTypes.bool,
  /**
   * If true, removes right margin of 170px
   */
  removeRightMargin: PropTypes.bool,
  /**
   * List of custom classes which apply on card
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

Card.defaultProps = {
  removeLeftMargin: false,
  removeRightMargin: false
}

export default Card

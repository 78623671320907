import React from 'react'
import PropTypes from 'prop-types'
import styles from './Bold.module.scss'

import classNames from 'classnames'

const Bold = ({ children, type, customClasses }) => {
  const boldClasses = classNames(styles.bold, styles[type], customClasses)

  return <span className={boldClasses}>{children}</span>
}

Bold.propTypes = {
  /**
   * Content to make bold
   */
  children: PropTypes.any.isRequired,
  /**
   * Weight of bold
   */
  type: PropTypes.oneOf(['bold', 'medium']),
  /**
   * List of custom classes which apply on content to make bold
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

Bold.defaultProps = {
  type: 'bold'
}

export default Bold

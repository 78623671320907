import React from 'react'
import PropTypes from 'prop-types'
import styles from './Radio.module.scss'
import _uniqueId from 'lodash/uniqueId'
import classNames from 'classnames'
import { Text } from '../../Typography'

const Radio = ({
  label,
  value,
  checked,
  disabled,
  onChange,
  labelPosition,
  labelCustomClasses,
  inputCustomClasses
}) => {
  const id = _uniqueId('radio-')
  const isLabelOnLeft = labelPosition === 'left'

  const labelClasses = classNames(
    { [styles.leftLabel]: isLabelOnLeft },
    { [styles.rightLabel]: !isLabelOnLeft },
    { [styles.disabled]: disabled },
    labelCustomClasses
  )
  const inputClasses = classNames(inputCustomClasses)

  const labelHtml = (
    <label htmlFor={id} className={labelClasses}>
      <Text>{label}</Text>
    </label>
  )

  return (
    <div>
      {isLabelOnLeft && labelHtml}
      <input
        type='radio'
        value={value || label}
        id={id}
        onChange={(e) => onChange(e.target.value)}
        className={inputClasses}
        disabled={disabled}
        checked={!!checked}
      />
      {!isLabelOnLeft && labelHtml}
    </div>
  )
}

Radio.propTypes = {
  /**
   * Label of radio button
   */
  label: PropTypes.string.isRequired,
  /**
   * Value associated with input
   * If absent, label becomes the value
   */
  value: PropTypes.string,
  /**
   * If true, the radio will be checked initially
   */
  checked: PropTypes.bool,
  /**
   * If true, the radio will be permanently disabled
   */
  disabled: PropTypes.bool,
  /**
   * Optional change handler
   * Will be provided with radio value as input
   */
  onChange: PropTypes.func,
  /**
   * Position of label relative to the radio button
   */
  labelPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * List of custom classes which apply on label
   */
  labelCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of custom classes which apply on input
   */
  inputCustomClasses: PropTypes.arrayOf(PropTypes.string)
}

Radio.defaultProps = {
  disabled: false,
  checked: false,
  labelPosition: 'right'
}

export default Radio

import React from 'react'
import PropTypes from 'prop-types'
import { Bold, Text } from '../Typography'
import classNames from 'classnames'
import styles from './Alert.module.scss'
import { ReactComponent as DangerIcon } from './danger.svg'
import { ReactComponent as GreyIcon } from './grey.svg'
import { ReactComponent as InfoIcon } from './info.svg'
import { ReactComponent as PrimaryIcon } from './primary.svg'
import { ReactComponent as SuccessIcon } from './success.svg'
import { ReactComponent as WarningIcon } from './warning.svg'

const Alert = ({ children, type, customClasses }) => {
  const classes = classNames(styles.alert, customClasses)

  const getIcon = () => {
    switch (type) {
      case 'primary':
        return <PrimaryIcon />
      case 'warning':
        return <WarningIcon />
      case 'success':
        return <SuccessIcon />
      case 'danger':
        return <DangerIcon />
      case 'info':
        return <InfoIcon />
      default:
        return <GreyIcon />
    }
  }

  return (
    <div className={classes}>
      {getIcon()}
      <Text type={type}>
        <Bold type='medium'>{children}</Bold>
      </Text>
    </div>
  )
}

Alert.propTypes = {
  /**
   * Alert text content
   */
  children: PropTypes.string.isRequired,
  /**
   * Type of alert
   */
  type: PropTypes.oneOf(['primary', 'warning', 'success', 'danger', 'info']),
  /**
   * List of custom classes which apply on alert container
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

export default Alert

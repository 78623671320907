import React from 'react'
import PropTypes from 'prop-types'
import styles from './Tag.module.scss'
import { Bold, Text } from '../Typography'
import classNames from 'classnames'

const Tag = ({ text, icon, type, customClasses }) => {
  const hasText = !!text
  const hasIcon = !!icon

  const containerClasses = classNames(
    styles.container,
    { [styles.noText]: !hasText, [styles[type]]: !!type },
    customClasses
  )

  return (
    <div className={containerClasses}>
      {hasIcon && <img src={icon} alt='tag icon' />}
      {hasText && (
        <Text type={type}>
          <Bold type='medium'>{text}</Bold>
        </Text>
      )}
    </div>
  )
}

Tag.propTypes = {
  /**
   * Text of tag
   */
  text: PropTypes.string,
  /**
   * Path to image or SVG
   */
  icon: PropTypes.string,
  /**
   * Type of tag
   */
  type: PropTypes.oneOf(['primary', 'warning', 'success', 'danger', 'info']),
  /**
   * List of custom classes which apply on tag container
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

export default Tag

import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './Modal.module.scss'
import { ReactComponent as Cross } from './cross.svg'
import { Bold, Title } from '../Typography'

const Modal = ({ children, title, close, customClasses }) => {
  const classes = classNames(styles.modal, customClasses)

  return (
    <div className={styles.container}>
      <div className={classes}>
        <div className={styles.title}>
          <Title level={2} textCase='capitalize'>
            <Bold type='medium'>{title}</Bold>
          </Title>
          <Cross onClick={close} className={styles.closeIcon} />
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  /**
   * Modal body content
   */
  children: PropTypes.any.isRequired,
  /**
   * Modal title
   */
  title: PropTypes.string.isRequired,
  /**
   * Required function to be invoked on clicking close
   */
  close: PropTypes.func.isRequired,
  /**
   * List of custom classes which apply on alert container
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

export default Modal

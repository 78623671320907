import React from 'react'
import styles from './Drawer.module.scss'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Button from '../Button'
import { ReactComponent as Cross } from './cross.svg'

const Drawer = ({
  children,
  show = false,
  backdrop = true,
  close,
  topOffset = 0,
  customClasses
}) => {
  const containerClasses = classNames(
    styles.container,
    {
      [styles.show]: show,
      [styles.backdrop]: backdrop,
      [styles.chromeFooter]: navigator.appVersion.indexOf('Chrome/') !== -1
    },
    customClasses
  )

  const drawerClasses = classNames(styles.drawer)

  return (
    <div className={containerClasses} style={{ top: `${topOffset}px` }}>
      <div className={drawerClasses} style={{ top: `${topOffset}px` }}>
        <Button type='text' onClick={close} customClasses={[styles.closeIcon]}>
          <Cross />
        </Button>
        {children}

        <footer className={styles.drawerFooter}>
          <Button onClick={close}>Done</Button>
        </footer>
        {/* Hack to add margin */}
        <div style={{ height: `${topOffset + 40}px` }} />
      </div>
    </div>
  )
}

Drawer.propTypes = {
  /**
   * Drawer contents
   */
  children: PropTypes.any,
  /**
   * Control to show drawer
   */
  show: PropTypes.bool,
  /**
   * Control to enable backdrop
   */
  backdrop: PropTypes.bool,
  /**
   * Required function to be invoked on clicking close
   */
  close: PropTypes.func.isRequired,
  /**
   * Top offset in px for container and drawer
   * Useful when handling layout with header
   */
  topOffset: PropTypes.number,
  /**
   * List of custom classes on container
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

export default Drawer

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Section.module.scss'
import { Text } from '../../../Typography'

import { ReactComponent as ChevronDownIcon } from './chevron-down.svg'

const Section = ({
  title,
  onClick,
  isSelected,
  sectionItems,
  selectedSubSectionIndex
}) => {
  const hasChildren = sectionItems && sectionItems.length > 0

  const titleClasses = classNames(styles.title, {
    [styles.selectedTitle]: isSelected && !hasChildren
  })
  const textClasses = classNames(styles.text, {
    [styles.selectedText]: isSelected
  })
  const iconClasses = classNames(styles.icon, {
    [styles.up]: hasChildren && isSelected
  })

  return (
    <div>
      <div className={titleClasses} onClick={onClick}>
        <Text customClasses={[textClasses]}>{title}</Text>
        {hasChildren && (
          <div className={iconClasses}>
            <ChevronDownIcon />
          </div>
        )}
      </div>
      {isSelected && hasChildren && (
        <div className={styles.subSection}>
          {sectionItems.map((item, i) => (
            <div
              key={i}
              className={classNames(styles.item, {
                [styles.selectedItem]: i === selectedSubSectionIndex
              })}
              onClick={item.onClick}
            >
              <Text customClasses={[styles.text]}>{item.text}</Text>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  sectionItems: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, onClick: PropTypes.func })
  ),
  isSelected: PropTypes.bool,
  selectedSubSectionIndex: PropTypes.number,
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

Section.defaultProps = {
  sectionItems: [],
  isSelected: false,
  selectedSubSectionIndex: 0
}

export default Section

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Navbar.module.scss'

const Navbar = ({ items, selectedItemIndex }) => {
  return (
    <div className={styles.container}>
      <div className={styles.items}>
        {items.map((item, i) => (
          <div
            key={i}
            className={classNames(styles.item, {
              [styles.selected]: i === selectedItemIndex
            })}
          >
            <img onClick={item.onClick} src={item.icon} alt='nav-icon' />
          </div>
        ))}
      </div>
    </div>
  )
}

Navbar.propTypes = {
  /**
   * Ordered list of nav bar's icon and on click function
   * Icon is a required property, on click is optional
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func
    })
  ).isRequired,
  /**
   * Current active nav item
   * Has to be a whole number
   */
  selectedItemIndex: PropTypes.number,
  /**
   * List of custom classes which apply on container
   */
  containerCustomClasses: PropTypes.arrayOf(PropTypes.string)
}

Navbar.defaultProps = {
  selectedItemIndex: 0
}

export default Navbar

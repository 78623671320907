import React from 'react'
import { Link as RouterLink } from '@reach/router'
import PropTypes from 'prop-types'
import styles from './Link.module.scss'

import classNames from 'classnames'

const Link = ({
  children,
  path,
  newTab,
  external,
  disabled,
  customClasses
}) => {
  const classes = classNames(
    styles.link,
    {
      [styles.disabled]: disabled
    },
    customClasses
  )

  const target = newTab ? '_blank' : '_self'
  return (
    <React.Fragment>
      {external ? (
        <a href={path} target={target} className={classes}>
          {children}
        </a>
      ) : (
        <RouterLink to={path} target={target} className={classes}>
          {children}
        </RouterLink>
      )}
    </React.Fragment>
  )
}

Link.propTypes = {
  /**
   * link contents
   */
  children: PropTypes.any.isRequired,
  /**
   * URL of the link
   */
  path: PropTypes.string.isRequired,
  /**
   * Specifies whether to open in new tab
   */
  newTab: PropTypes.bool,
  /**
   * Specifies whether it is an external link
   */
  external: PropTypes.bool,
  /**
   * Control to disable button
   */
  disabled: PropTypes.bool,
  /**
   * List of custom classes
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

Link.defaultProps = {
  newTab: false,
  external: false,
  disabled: false
}

export default Link

import React from 'react'
import ReactSelect from 'react-select'
import styles from './Select.module.scss'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import { Text } from '../Typography'

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${state.isFocused ? '#325ef1' : '#E4E8EB'}`,
    borderRadius: '4px',
    height: '32px',
    width: '100%',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'white',
    boxShadow: '1px 1px 3px 0 rgba(206,206,206,0.14)',
    '&:hover': {
      borderColor: state.isDisabled
        ? 'rgba(239, 239, 239, 0.3)'
        : state.isFocused
        ? '#325ef1'
        : '#b3b3b3'
    }
  }),
  container: (provided) => ({
    ...provided,
    width: '100%',
    marginTop: '8px'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#526174',
    fontFamily: "'Gotham Rounded Medium'",
    fontSize: '12px',
    letterSpacing: '0',
    lineHeight: '14px',
    outline: 'none',
    opacity: '0.3'
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '12.5px'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#526174',
    fontFamily: "'Gotham Rounded Book'",
    fontSize: '12px',
    letterSpacing: '0',
    lineHeight: '14px',
    backgroundColor: state.isSelected ? '#325ef1' : provided.backgroundColor
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#526174',
    fontFamily: "'Gotham Rounded Book'",
    fontSize: '12px',
    letterSpacing: '0',
    lineHeight: '14px',
    outline: 'none',
    opacity: '1'
  }),
  menu: (provided) => ({
    ...provided,
    color: '#526174',
    fontFamily: "'Gotham Rounded Book'",
    fontSize: '12px',
    letterSpacing: '0',
    lineHeight: '14px',
    outline: 'none',
    zIndex: '7'
  })
}

const Select = ({
  options,
  label,
  onChange,
  value,
  disabled,
  placeholder,
  containerCustomClasses,
  labelCustomClasses
}) => {
  const containerClasses = classNames(
    styles.inputContainer,
    containerCustomClasses
  )
  const labelClasses = classNames(styles.label, labelCustomClasses)

  return (
    <div className={containerClasses}>
      {!!label && (
        <Text weight='small' customClasses={[labelClasses]}>
          {label}
        </Text>
      )}
      <ReactSelect
        options={options}
        styles={selectStyles}
        onChange={onChange}
        value={value}
        isDisabled={disabled}
        placeholder={placeholder}
      />
    </div>
  )
}

Select.propTypes = {
  /**
   * Array of options that populate the select menu
   */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Label for select input
   */
  label: PropTypes.string,
  /**
   * Function to handle change events on the select
   */
  onChange: PropTypes.func,
  /**
   * The value of the select; reflected by the selected option
   */
  value: PropTypes.string,
  /**
   * Control to disable input
   */
  disabled: PropTypes.bool,
  /**
   * Placeholder for the select value
   */
  placeholder: PropTypes.string,
  /**
   * List of custom classes which apply on container
   */
  containerCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of custom classes which apply on label
   */
  labelCustomClasses: PropTypes.arrayOf(PropTypes.string)
}

Select.defaultProps = {
  placeholder: 'Select...',
  disabled: false
}
export default Select

import React from 'react'
import classNames from 'classnames'
import Button from '../Button'
import { Bold } from '../Typography'
import styles from './Breadcrumbs.module.scss'
import PropTypes from 'prop-types'

const Breadcrumbs = ({ breadcrumbs, customClassNames }) => {
  return (
    <div className={classNames(customClassNames)}>
      {breadcrumbs?.map((breadcrumb, i) => (
        <div key={i} className={styles.breadcrumb}>
          <Bold type='medium'>
            <Button
              type='text'
              onClick={breadcrumb.onClick}
              disabled={i === breadcrumbs.length - 1}
            >
              {breadcrumb.text}
            </Button>
          </Bold>
          {i !== breadcrumbs.length - 1 && (
            <span className={styles.slash}> / </span>
          )}
        </div>
      ))}
    </div>
  )
}

Breadcrumbs.propTypes = {
  /**
   * Ordered list of breadcrumbs, the last element is assumed to be the current crumb
   */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func
    })
  ).isRequired,
  /**
   * List of custom classes which apply on container
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

export default Breadcrumbs

import React from 'react'
import PropTypes from 'prop-types'
import styles from './Text.module.scss'

import classNames from 'classnames'

const Text = ({ children, weight, type, customClasses }) => {
  const titleClasses = classNames(
    styles.text,
    styles[weight],
    { [styles[type]]: !!type },
    customClasses
  )

  return <div className={titleClasses}>{children}</div>
}

Text.propTypes = {
  /**
   * Text content
   */
  children: PropTypes.any.isRequired,
  /**
   * Weight of text
   */
  weight: PropTypes.oneOf(['text', 'small', 'paragraph']),
  /**
   * Type of text
   */
  type: PropTypes.oneOf(['primary', 'warning', 'success', 'danger', 'info']),
  /**
   * List of custom classes which apply on text
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

Text.defaultProps = {
  weight: 'text'
}

export default Text

import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Sidebar.module.scss'
import { Section } from './Section'
import { Title } from '../../Typography'
import { ReactComponent as ArrowClose } from './close.svg'
import { ReactComponent as ArrowOpen } from './open.svg'

const Sidebar = ({
  heading,
  sections,
  selectedSectionIndex,
  selectedSubSectionIndex
}) => {
  const [hide, setHide] = useState(false)

  const classes = classNames(styles.container, {
    [styles.minimized]: hide
  })

  return (
    <Fragment>
      {hide ? (
        <ArrowOpen className={styles.arrow} onClick={() => setHide(!hide)} />
      ) : (
        <ArrowClose className={styles.arrow} onClick={() => setHide(!hide)} />
      )}
      <div className={classes}>
        <Title level={2} customClasses={[styles.title]}>
          {heading}
        </Title>
        <div className={styles.sections}>
          {sections.map((section, i) => (
            <Section
              key={i}
              title={section.title}
              onClick={section.onClick}
              isSelected={i === selectedSectionIndex}
              sectionItems={section.sectionItems}
              selectedSubSectionIndex={selectedSubSectionIndex}
            />
          ))}
        </div>
      </div>
    </Fragment>
  )
}

Sidebar.propTypes = {
  heading: PropTypes.any.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      sectionItems: PropTypes.arrayOf(
        PropTypes.shape({ text: PropTypes.string, onClick: PropTypes.func })
      )
    })
  ),
  selectedSectionIndex: PropTypes.number,
  selectedSubSectionIndex: PropTypes.number,
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

Sidebar.defaultProps = {
  selectedSectionIndex: 0,
  selectedSubSectionIndex: 0
}

export default Sidebar

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Menu.module.scss'
import { Navbar } from '../Navbar'
import { Sidebar } from '../Sidebar'

const Menu = ({
  navbarItems,
  sidebars,
  selectedNavItem,
  selectedSidebarItem,
  selectedSidebarSubsection,
  customClasses
}) => {
  const classes = classNames(styles.container, customClasses)

  return (
    <div className={classes}>
      <Navbar items={navbarItems} selectedItemIndex={selectedNavItem} />
      <Sidebar
        heading={sidebars[selectedNavItem || 0].heading}
        sections={sidebars[selectedNavItem || 0].sections}
        selectedSectionIndex={selectedSidebarItem || 0}
        selectedSubSectionIndex={selectedSidebarSubsection || 0}
      />
    </div>
  )
}

Menu.propTypes = {
  /**
   * Ordered list of nav bar's icon and on click function
   * Icon is a required property, on click is optional
   */
  navbarItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func
    })
  ).isRequired,
  /**
   * Ordered list of sidebars
   * Each element contains a single sidebars contents: heading and sections
   * Sections is an ordered list of section heading, subsection items and optional onClick handler
   */
  sidebars: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.any.isRequired,
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          onClick: PropTypes.func,
          sectionItems: PropTypes.arrayOf(
            PropTypes.shape({ text: PropTypes.string, onClick: PropTypes.func })
          )
        })
      )
    })
  ).isRequired,
  /**
   * Selected nav item
   * Has to be a whole number
   */
  selectedNavItem: PropTypes.number,
  /**
   * Selected sidebar item
   * Has to be a whole number
   */
  selectedSidebarItem: PropTypes.number,
  /**
   * Selected subsection in the selected sidebar item
   * Has to be a whole number
   */
  selectedSidebarSubsection: PropTypes.number
}

Menu.defaultProps = {
  selectedNavItem: 0,
  selectedSidebarItem: 0,
  selectedSidebarSubsection: 0
}

export default Menu

import React from 'react'
import _uniqueId from 'lodash/uniqueId'
import classNames from 'classnames'
import styles from './Checkbox.module.scss'
import { Text } from '../Typography'
import PropTypes from 'prop-types'

const Checkbox = ({
  label,
  checked,
  disabled,
  onChange,
  labelPosition,
  containerCustomClasses,
  labelCustomClasses,
  inputCustomClasses
}) => {
  const id = _uniqueId('checkbox-')
  const isLabelOnLeft = labelPosition === 'left'

  const labelClasses = classNames(
    { [styles.leftLabel]: isLabelOnLeft },
    { [styles.rightLabel]: !isLabelOnLeft },
    { [styles.disabled]: disabled },
    labelCustomClasses
  )
  const inputClasses = classNames(inputCustomClasses)
  const containerClasses = classNames(containerCustomClasses, {
    [styles.container]: !isLabelOnLeft
  })

  const labelHtml = (
    <label htmlFor={id} className={labelClasses}>
      <Text>{label}</Text>
    </label>
  )

  return (
    <div className={containerClasses}>
      {isLabelOnLeft && labelHtml}
      <input
        type='checkbox'
        id={id}
        checked={checked}
        onChange={onChange}
        className={inputClasses}
        disabled={disabled}
      />
      {!isLabelOnLeft && labelHtml}
    </div>
  )
}

Checkbox.propTypes = {
  /**
   * Label of radio button
   */
  label: PropTypes.string.isRequired,
  /**
   * If true, the box's initial state will be checked
   */
  checked: PropTypes.bool,
  /**
   * If true, the checkbox will be permanently disabled
   */
  disabled: PropTypes.bool,
  /**
   * Optional change handler
   */
  onChange: PropTypes.func,
  /**
   * Position of label relative to the checkbox
   */
  labelPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * List of custom classes which apply on container
   */
  containerCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of custom classes which apply on label
   */
  labelCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of custom classes which apply on input
   */
  inputCustomClasses: PropTypes.arrayOf(PropTypes.string)
}

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  labelPosition: 'right'
}

export default Checkbox

import React from 'react'
import PropTypes from 'prop-types'
import styles from './Title.module.scss'

import classNames from 'classnames'

const Title = ({ children, level, textCase, customClasses }) => {
  const titleClasses = classNames(
    styles.title,
    styles[`level-${level}`],
    styles[textCase],
    customClasses
  )

  return <div className={titleClasses}>{children}</div>
}

Title.propTypes = {
  /**
   * Title content
   */
  children: PropTypes.any.isRequired,
  /**
   * Level of title, should be between 1-3
   */
  level: PropTypes.oneOf([1, 2, 3]),
  /**
   * Set case of text
   */
  textCase: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize', 'none']),
  /**
   * List of custom classes which apply on title
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

Title.defaultProps = {
  level: 1,
  textCase: 'uppercase'
}

export default Title

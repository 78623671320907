import React from 'react'
import ReactDatePicker from 'react-datepicker'
import classNames from 'classnames'
import styles from './DatePicker.module.scss'
import PropTypes from 'prop-types'
import { Text } from '../Typography'

const DatePicker = ({
  label,
  dateFormat,
  selected,
  onChange,
  containerCustomClasses,
  labelCustomClasses,
  placement,
  ...args
}) => {
  const containerClasses = classNames(styles.container, containerCustomClasses)
  const labelClasses = classNames(styles.label, labelCustomClasses)

  return (
    <div className={containerClasses}>
      {!!label && (
        <Text weight='small' customClasses={labelClasses}>
          {label}
        </Text>
      )}
      <ReactDatePicker
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
        showPopperArrow={false}
        dateFormat={dateFormat}
        selected={selected}
        onChange={onChange}
        popperPlacement={placement}
        {...args}
      />
    </div>
  )
}

DatePicker.propTypes = {
  /**
   * Label text of the date picker
   */
  label: PropTypes.string,
  /**
   * Format how date has to be shown
   */
  dateFormat: PropTypes.string,
  /**
   * Date selected at the start
   */
  selected: PropTypes.instanceOf(Date),
  /**
   * Optional change handler
   */
  onChange: PropTypes.func,
  /**
   * List of custom classes which apply on container
   */
  containerCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of custom classes which apply on label
   */
  labelCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * Placement of date picker popover
   */
  placement: PropTypes.string
}

DatePicker.defaultProps = {
  dateFormat: 'MMMM d, yyyy',
  selected: new Date(),
  placement: 'bottom-end'
}

export default DatePicker

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

registerPlugin(
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageValidateSize,
  FilePondPluginImagePreview
)

/**
 * Dropzone (file uploader) component using https://pqina.nl/filepond
 * By default, file size validation, file type validation,
 * image size validation and image preview is setup
 */
const Dropzone = ({
  files = [],
  onUpdateFiles,
  onReorderFiles,
  label = 'Drop file or <span class="filepond--label-action">Browse</span>',
  maxFiles = null,
  maxFileSize = '5MB',
  acceptedFileTypes,
  imageMinWidth = 1,
  imageMaxWidth = 65535,
  imageMinHeight = 1,
  imageMaxHeight = 65535,
  imagePreviewMaxHeight = 256,
  allowMultiple = false,
  allowReorder = false,
  disabled = false,
  customClasses,
  args
}) => {
  const containerClasses = classNames(customClasses)

  return (
    <FilePond
      files={files}
      onupdatefiles={(fileItems) => onUpdateFiles && onUpdateFiles(fileItems)}
      onreorderfiles={(fileItems, origin, target) =>
        onReorderFiles && onReorderFiles(fileItems, origin, target)
      }
      labelIdle={label}
      maxFileSize={maxFileSize}
      acceptedFileTypes={acceptedFileTypes}
      imageValidateSizeMinWidth={imageMinWidth}
      imageValidateSizeMaxWidth={imageMaxWidth}
      imageValidateSizeMinHeight={imageMinHeight}
      imageValidateSizeMaxHeight={imageMaxHeight}
      imagePreviewMaxHeight={imagePreviewMaxHeight}
      allowMultiple={allowMultiple}
      allowReorder={allowReorder}
      allowReplace={false}
      disabled={disabled}
      maxFiles={maxFiles}
      credits={false}
      maxParallelUploads={1}
      className={[containerClasses]}
      {...args}
    />
  )
}

Dropzone.propTypes = {
  /**
   * List of initial files
   */
  files: PropTypes.array,
  /**
   * Function to be called when a file has been added or removed,
   * receives a list of file items
   */
  onUpdateFiles: PropTypes.func.isRequired,
  /**
   * Only when allowMultiple and allowReorder are true
   * Called when the files list has been reordered, receives reordered list of files
   * plus file origin and target index
   */
  onReorderFiles: PropTypes.func.isRequired,
  /**
   * Label shown to indicate this is a drop area
   */
  label: PropTypes.string,
  /**
   * The maximum number of files that can be uploaded
   */
  maxFiles: PropTypes.number,
  /**
   * The maximum size of a file, for instance 5MB or 750KB
   */
  maxFileSize: PropTypes.string,
  /**
   * Array of accepted file types
   * Eg: ['image/*'] will accept all images, ['image/png', 'image/jpeg'] will only accepts PNGs and JPEGs
   */
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
  /**
   * The minimum image width
   */
  imageMinWidth: PropTypes.number,
  /**
   * The maximum image width
   */
  imageMaxWidth: PropTypes.number,
  /**
   * The minimum image height
   */
  imageMinHeight: PropTypes.number,
  /**
   * The maximum image height
   */
  imageMaxHeight: PropTypes.number,
  /**
   * The maximum image preview height
   */
  imagePreviewMaxHeight: PropTypes.number,
  /**
   * Enable or disable adding multiple files
   */
  allowMultiple: PropTypes.bool,
  /**
   * Allows the user to reorder the file items
   */
  allowReorder: PropTypes.bool,
  /**
   * Disables the input
   */
  disabled: PropTypes.bool,
  /**
   * List of custom classes on container
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

export default React.memo(Dropzone)

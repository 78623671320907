import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Radio } from '../Radio'
import styles from './RadioGroup.module.scss'

/**
 * Creates a radio group
 */
const RadioGroup = ({
  radios,
  selectedValue,
  onChange,
  rows = 1,
  columns = radios.length,
  rowGap,
  columnGap,
  labelPosition,
  containerCustomClasses,
  labelCustomClasses,
  inputCustomClasses
}) => {
  if (rows * columns !== radios.length) {
    throw new Error('Rows * columns is not equal to number of radios')
  }

  const containerClasses = classNames(
    styles.groupContainer,
    containerCustomClasses
  )

  return (
    <div
      className={containerClasses}
      style={{
        gridTemplateRows: `repeat(${rows}, max-content)`,
        gridTemplateColumns: `repeat(${columns}, max-content)`,
        gridGap: `${rowGap}px ${columnGap}px`
      }}
    >
      {radios.map((radio, i) => (
        <Radio
          key={i}
          label={radio.label}
          value={radio.value}
          checked={(radio.value || radio.label) === selectedValue}
          disabled={radio.disabled}
          onChange={onChange}
          labelPosition={labelPosition}
          labelCustomClasses={labelCustomClasses}
          inputCustomClasses={inputCustomClasses}
        />
      ))}
    </div>
  )
}

RadioGroup.propTypes = {
  /**
   * Array of radios in group
   */
  radios: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Label of each radio button in group
       */
      label: PropTypes.string.isRequired,
      /**
       * Value associated with input
       * Used for deciding which radio is selected
       */
      value: PropTypes.string,
      /**
       * If true, this radio in group will be permanently disabled
       */
      disabled: PropTypes.bool
    })
  ).isRequired,
  /**
   * To determine which radio should be checked initially
   * If value is absent, label is used for comparison instead
   */
  selectedValue: PropTypes.string.isRequired,
  /**
   * Optional change handler for radio group
   * Will be provided with selected radio value as input
   */
  onChange: PropTypes.func,
  /**
   * Number of rows to place the radios in the radio group
   */
  rows: PropTypes.number.isRequired,
  /**
   * Number of columns to place the radios in the radio group
   */
  columns: PropTypes.number.isRequired,
  /**
   * Gap (in px) between rows (nullish values default to 26)
   */
  rowGap: PropTypes.number,
  /**
   * Gap (in px) between columns (nullish values default to 80)
   */
  columnGap: PropTypes.number,
  /**
   * Position of label relative to the radio buttons in group
   */
  labelPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * List of custom classes which apply on container
   */
  containerCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of custom classes which apply on label
   */
  labelCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of custom classes which apply on input
   */
  inputCustomClasses: PropTypes.arrayOf(PropTypes.string)
}

RadioGroup.defaultProps = {
  labelPosition: 'right',
  rowGap: 26,
  columnGap: 80
}

export default RadioGroup

import React from 'react'
import PropTypes from 'prop-types'
import styles from './Stepper.module.scss'
import classNames from 'classnames'

const Stepper = ({
  numberOfSteps,
  stepsData,
  currentStep,
  containerCustomClasses,
  completeStepCustomClasses,
  currentStepCustomClasses,
  incompleteStepCustomClasses
}) => {
  const getStepClasses = (index) => {
    if (currentStep === index + 1) {
      return classNames(
        styles.step,
        styles.currentStep,
        currentStepCustomClasses
      )
    } else if (currentStep > index + 1) {
      return classNames(
        styles.step,
        styles.completeStep,
        completeStepCustomClasses
      )
    } else {
      return classNames(styles.step, incompleteStepCustomClasses)
    }
  }

  const getStepIcon = (index) => {
    const step = index + 1

    if (currentStep === step && stepsData[index]?.icons?.current) {
      return (
        <img
          src={stepsData[index].icons.current}
          alt={step}
          className={styles.stepIcon}
        />
      )
    } else if (currentStep > step && stepsData[index]?.icons?.complete) {
      return (
        <img
          src={stepsData[index].icons.complete}
          alt={step}
          className={styles.stepIcon}
        />
      )
    } else if (stepsData[index]?.icons?.incomplete) {
      return (
        <img
          src={stepsData[index].icons.incomplete}
          alt={step}
          className={styles.stepIcon}
        />
      )
    }

    return step
  }

  return (
    <div
      className={classNames(styles.stepperContainer, containerCustomClasses)}
    >
      {!!numberOfSteps && <div className={styles.bar} />}
      {[...Array(numberOfSteps)].map((_, index) => (
        <div key={index} className={styles.stepContainer}>
          <div className={`${getStepClasses(index)}`}>{getStepIcon(index)}</div>
          {!!stepsData[index]?.title && (
            <div
              className={`${styles.stepText}
                ${currentStep === index + 1 ? styles.currentStepText : ''}
                ${currentStep > index + 1 ? styles.completeStepText : ''}`}
            >
              {stepsData[index].title}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

Stepper.propTypes = {
  /**
   * Number of steps in stepper
   */
  numberOfSteps: PropTypes.number.isRequired,
  /**
   * Optional ordered list of step's title or icon
   * If not provided, the stepper is made with natural numbers
   */
  stepsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icons: PropTypes.shape({
        incomplete: PropTypes.string,
        current: PropTypes.string,
        complete: PropTypes.string
      })
    })
  ),
  /**
   * Current step in the stepper (has to be a natural number)
   */
  currentStep: PropTypes.number,
  /**
   * List of custom classes which apply on container
   */
  containerCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of custom classes which apply on a completed step
   */
  completeStepCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of custom classes which apply on the current step
   */
  currentStepCustomClasses: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of custom classes which apply on an incomplete step
   */
  incompleteStepCustomClasses: PropTypes.arrayOf(PropTypes.string)
}

Stepper.defaultProps = {
  currentStep: 1
}

export default Stepper

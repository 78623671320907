import React from 'react'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import 'react-table-v6/react-table.css'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import styles from './Table.module.scss'
import ReactTable from 'react-table-v6'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Alert } from '../Alert'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

/**
 * Table component using https://github.com/tannerlinsley/react-table/tree/v6
 * By default, pagination, sorting, filtering, and resizable columns are disabled
 * Default filter method has been overridden to compare substrings case insensitively
 */
const Table = ({ data, columns, noDataText = 'No data found', args }) => {
  const classes = classNames('-striped', '-highlight', styles.container)

  const cols = columns.map((col) => {
    if (col.filterable && !col.Filter) {
      return {
        ...col,
        Filter: ({ filter, onChange }) => (
          <input
            type='text'
            placeholder='Search...'
            value={filter ? filter.value : ''}
            style={{ width: '100%', fontFamily: 'Gotham Rounded Light' }}
            onChange={(event) => onChange(event.target.value)}
          />
        )
      }
    }

    return col
  })

  return (
    <ReactTableFixedColumns
      data={data}
      columns={cols}
      minRows={0}
      pageSize={data.length}
      showPagination={false}
      sortable={false}
      multiSort={false}
      resizable={false}
      noDataText={noDataText ? <Alert type='info'>{noDataText}</Alert> : null}
      defaultFilterMethod={(filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
          : true
      }}
      className={classes}
      {...args}
    />
  )
}

Table.propTypes = {
  /**
   * Contains data to be populated in table
   * See story for structure for more clarity
   */
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   * Contains columns of table
   * See story for structure for more clarity
   */
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   * Alert text to show when no data is found
   */
  noDataText: PropTypes.any
}

export default Table

import React from 'react'
import styles from './Footer.module.scss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Footer = ({ children, customClasses }) => {
  const classes = classNames(styles.footer, customClasses)
  return (
    <div>
      {/* Hack to work across browsers */}
      <div style={{ height: '84px' }} />
      <footer className={classes}>{children}</footer>
    </div>
  )
}

Footer.propTypes = {
  /**
   * Contents in the footer
   */
  children: PropTypes.any,
  /**
   * List of custom classes which apply on footer
   */
  customClasses: PropTypes.arrayOf(PropTypes.string)
}

export default Footer
